import React from "react";
import { Helmet } from "react-helmet";

import ContactForm from "./ContactForm";

import { Container, Row, Col } from "reactstrap";
import "./style.css";
import config from "_config";
import { LOAD_CMS, GET_CMS,LOAD_CMS_CONTACT } from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
function ContactUs() {

  const dispatch = useDispatch();

  React.useEffect(() => {   
    dispatch({ 
      type: LOAD_CMS_CONTACT });
  }, [dispatch]);

  const { get_contact } = useSelector(
    (state) => ({
      get_contact: state.contact.contact,     
    }),
    shallowEqual
  );

  return (
    <>
      <Helmet>
        <title>Credit Experts ${config.company} | Everyone's Approved</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={`Credit Experts and Used cars in 
        ${config.city} get more deals by ${config.company}| Everyone's Approved`}
        />
      </Helmet>
      <hr />
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-left mb-3" md="12">
            {/* <h1 className="blue-title">Contact sfsf</h1>

            <h2 className="red-sub-title mb-3">We Are Open</h2>
            <p className="description">
              Learn first hand why a large number of our sales are to repeat
              customers and from referrals to their friends and family. We look
              forward to earning your trust and business on your next pre-owned
              vehicle and many more to follow.
            </p> */}
             <div
                    className="extra-text-finance"
                    dangerouslySetInnerHTML={{ __html: get_contact["results"]?.[0]?.subjectContent?.replace(/(<? *script)/gi, 'illegalscript') }} >
                  </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="light-border no-gutters">
          <Col className="mr-auto" lg="6" md="6" sm="12" xs="12">
            <ContactForm />
          </Col>
          <Col md="5" className="mt-4">
            {/* <p>
              <strong>Location</strong>
            </p>
            <p>{config.company}</p>
            <p>
              {config.address}, {config.city},
            </p>
            <p>
              {config.province}, {config.pincode}
            </p>
            <p>
              <strong>Telephone</strong>
            </p>
            <p>
              <a href={`tel:${config.phone}`}> {config.phoneformat} </a>
            </p>

            <p>
              <strong>Fax</strong>
            </p>
            <p>
              <a href={`tel:${config.fax}`}> {config.faxformat} </a>
            </p>

            <p>
              <strong>Email</strong>
            </p>
            <p>
              <a href={`mailto:${config.emailid}`}>{config.emailid}</a>
            </p> */}
            <div
                    className="extra-text-finance"
                    dangerouslySetInnerHTML={{ __html: get_contact["results"]?.[0]?.locationContent?.replace(/(<? *script)/gi, 'illegalscript') }} >
                  </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
