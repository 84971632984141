import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";

import demoImageUrl from "assets/images/other-images/image-to-come-dave-forde.jpg";

import Config from "_config.json";

const CarCard = ({ _p }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(demoImageUrl);
  const ServerUl = Config.filter((x) => x.name === "serverurl")[0].value;
  // console.log(_p)
  useEffect(() => {
    // console.log(_p.get_image_url)
    if (_p.get_image_url) {
      setImage(ServerUl + _p.get_image_url);
    } else if (_p.get_factory_image_url) {
      setImage(_p.get_factory_image_url);
    }
  }, [_p, ServerUl]);

  function truncate(str) {
    if (str?.length > 0) {
      return str.length > 180 ? str.substring(0, 180) + "..." : str;
    }
  }
  return (
    <>
      <Col md="3" id={_p.id}>
        {" "}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/inventory/${_p.id}/${_p.slug}`)}
        >
          <Card className="card-plain inventory-card card-product">
            <img
              alt={_p.slug}
              // className="image-left-pull-right"
              src={image}
            />
            <CardBody>
              <CardTitle>
                <Row>
                  <Col className="text-danger">
                    <span className="year-make">
                      {/* {_p.year} {_p.make} */}
                      {_p?.inventory_notes?.year_make_model_extra}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-danger">
                    <span className="model-trim">
                      {_p?.inventory_notes?.trim_extra}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="product-description">
                      <span
                        className="forced-product-description"
                        dangerouslySetInnerHTML={{
                          __html: truncate(
                            _p?.inventory_notes?.short_description
                          ),
                        }}
                      ></span>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-left">
                    <span className="list-price">
                      {_p.list_price !== "0.00" && _p.list_price
                        ? "$" + _p.list_price
                        : ""}
                    </span>
                  </Col>
                </Row>
              </CardTitle>

              <Row className="mb-n1">
                <Col>
                  <span className="kilometers">Kms: {_p.kilometers}</span>
                </Col>
                {/* <Col className="text-center">
                  {_p.location === 17 ? (
                    <div className="location-onsite">Onsite</div>
                  ) : (
                    <div className="location-offsite">Offsite</div>
                  )}
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        </div>
      </Col>
    </>
  );
};

export default CarCard;
