import React from "react";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";

import Flip from "react-reveal/Flip";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// reactstrap components
import {
  Container,
  Button,
  Form,
  Input,
  Row,
  Col,
  Collapse,
  FormGroup,
} from "reactstrap";

import Select from "react-select";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import { LeftArrow } from "utilities/SocialIcons";

import "./style.css";

var selectEmploymentOptions = [
  { value: 1, label: "Permanent" },
  { value: 2, label: "Contract" },
  { value: 3, label: "Seasonal" },
  { value: 4, label: "EI or Similar" },
  { value: 5, label: "Un-Employed" },
];

let years = [];
_.times(20, (i) => {
  years.push({ value: i, label: `${i}` });
});

let months = [];
_.times(11, (i) => {
  months.push({ value: i, label: `${i}` });
});

const Employment = ({ setDirection, setInSection, closeApplication }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors, setValue, control } =
    useForm();

  const [employment, setEmployment] = React.useState([]);
  const [employmentType, setEmploymentType] = React.useState(false);
  const [yearType, setYearType] = React.useState(false);
  const [monthType, setMonthType] = React.useState(false);

  const [prevEmploymentType, setPrevEmploymentType] = React.useState(false);
  const [prevYearType, setPrevYearType] = React.useState(false);
  const [prevMonthType, setPrevMonthType] = React.useState(false);

  const [employedIsOpen, setEmployedIsOpen] = React.useState(true);
  const [prevEmployedIsOpen, setPrevEmployedIsOpen] = React.useState(true);

  const [employerName, setEmployerName] = React.useState("Employer");
  const [prevEmployerName, setPrevEmployerName] = React.useState("Employer");

  // less than 2 years
  const [prevEmployment, setPrevEmployment] = React.useState(false);

  const { get_employment } = useSelector(
    (state) => ({
      get_employment: state.application.application,
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (_.has(get_employment, "employment")) {
      setEmployment(get_employment.employment);
      setEmploymentType(get_employment.employment.employment_type);
      setYearType(get_employment.employment.years_in_job);
      setMonthType(get_employment.employment.months_in_job);
      setPrevEmploymentType(get_employment.employment.prev_employment_type);
      setPrevYearType(get_employment.employment.prev_years_in_job);
      setPrevMonthType(get_employment.employment.prev_months_in_job);
    }
  }, [get_employment]);

  // React.useEffect(() => {
  //   register(
  //     { name: "employment_type" },
  //     { required: "Please source of income" }
  //   );
  //   register(
  //     { name: "years_in_job" },
  //     { required: "Please select years in job" }
  //   );
  //   register(
  //     { name: "months_in_job" },
  //     { required: "Please select months in job" }
  //   );
  // }, [register]);

  const onEmploymentChange = (selectedOption) => {
    setEmploymentType(selectedOption[0]);
    if (selectedOption[0].value > 3) {
      setEmployedIsOpen(false);
      setEmployerName("Source of Income");
    } else {
      setEmployedIsOpen(true);
      setEmployerName("Employer");
    }
  };

  React.useEffect(() => {
    if (employmentType.value > 3) {
      setEmployedIsOpen(false);
      setEmployerName("Source of Income");
    } else {
      setEmployedIsOpen(true);
      setEmployerName("Employer");
    }
  }, [employmentType]);

  const onPrevEmploymentChange = (selectedOption) => {
    setPrevEmploymentType(selectedOption);
    if (selectedOption > 3) {
      setPrevEmployedIsOpen(false);
      setPrevEmployerName("Source of Income");
    } else {
      setPrevEmployedIsOpen(true);
      setPrevEmployerName("Employer");
    }
  };

  React.useEffect(() => {
    if (prevEmploymentType) {
      if (prevEmploymentType > 3) {
        setPrevEmployedIsOpen(false);
        setPrevEmployerName("Source of Income");
      } else {
        setPrevEmployedIsOpen(true);
        setPrevEmployerName("Employer");
      }
    }
  }, [prevEmploymentType]);

  const onYearsChange = (e) => {
    if (e[0].value < 2) {
      setPrevEmployment(true);
    } else {
      setPrevEmployment(false);
    }
    setYearType(e[0]);
  };

  React.useEffect(() => {
    if (yearType) {
      if (yearType.value < 2) {
        setPrevEmployment(true);
      } else {
        setPrevEmployment(false);
      }
    }
  }, [yearType]);

  const onPrevYearsChange = (e) => {
    setPrevYearType(e[0]);
  };

  const onMonthsChange = (e) => {
    setMonthType(e[0]);
  };

  const onPrevMonthsChange = (e) => {
    setPrevMonthType(e[0]);
  };

  React.useEffect(() => {
    //
    setValue("employment_type", employmentType);
    setValue("years_in_job", yearType);
    setValue("months_in_job", monthType);
    // Previous
    setValue("prev_employment_type", prevEmploymentType);
    setValue("prev_years_in_job", prevYearType);
    setValue("prev_months_in_job", prevMonthType);
  }, [
    setValue,
    employmentType,
    yearType,
    monthType,
    prevEmploymentType,
    prevYearType,
    prevMonthType,
  ]);

  React.useEffect(() => {
    register(
      { name: "employment_type" },
      { required: "Please select a Source of Income" }
    );
    register(
      { name: "years_in_job" },
      {
        required: `Please select years ${
          employedIsOpen ? "in Job" : "receiving income"
        }`,
      }
    );
    register(
      { name: "months_in_job" },
      {
        required: `Please select months ${
          employedIsOpen ? "in Job" : "receiving income"
        }`,
      }
    );

    if (prevEmployment) {
      register(
        { name: "prev_employment_type" },
        { required: "Please select a Source of Previous Income" }
      );
      register(
        { name: "prev_years_in_job" },
        {
          required: `Please select years ${
            prevEmployedIsOpen ? "in Job" : "receiving previous income"
          }`,
        }
      );
      register(
        { name: "prev_months_in_job" },
        {
          required: `Please select months ${
            prevEmployedIsOpen ? "in Job" : "receiving previous income"
          }`,
        }
      );
    } else {
      register({ name: "prev_address" }, { required: false });
      register({ name: "prev_annual_income" }, { required: false });
      register({ name: "prev_employer" }, { required: false });
      register({ name: "prev_employer_emailid" }, { required: false });
      register({ name: "prev_employer_phone" }, { required: false });
      register({ name: "prev_employment_type" }, { required: false });
      register({ name: "prev_months_in_job" }, { required: false });
      register({ name: "prev_years_in_job" }, { required: false });
    }
  }, [register, employedIsOpen, prevEmployment, prevEmployedIsOpen]);

  const onSubmit = (data) => {
    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        employment: {
          ...data,
          employment_type: employmentType,
          years_in_job: yearType,
          months_in_job: monthType,
          prev_employment_type: prevEmploymentType,
          prev_years_in_job: prevYearType,
          prev_months_in_job: prevMonthType,
        },
      },
    });
    setDirection(true);
    setInSection(5);
  };
  //
  function GoBack() {
    setDirection(false);
    setInSection(3);
  }

  return (
    <>
      <Container>
        <LargeContainer>
          <Button close onClick={closeApplication} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                  <LeftArrow onClick={GoBack} />{" "}
                </span>
                Employment Information
              </h2>

              <p>Some information to help us complete the form.</p>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <p>
                Employment Type <span className="icon-danger">*</span>
              </p>

              <Controller
                as={Select}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                options={selectEmploymentOptions}
                value={employmentType ? employmentType : null}
                name="employment_type"
                className="text-dark"
                control={control}
                onChange={onEmploymentChange}
              />
              <p className="text-danger small">
                {employmentType
                  ? ""
                  : errors.employment_type && (
                      <span>{errors.employment_type.message}</span>
                    )}
              </p>
            </FormGroup>

            <FormGroup>
              <p>
                {employerName} <span className="icon-danger">*</span>
              </p>
              <Input
                className="border-input"
                name="employer"
                defaultValue={employment ? employment.employer : ""}
                placeholder={`${employerName}`}
                innerRef={register({
                  required: `Please give the ${employerName} Name`,
                })}
                type="text"
              />
              <p className="text-danger small">
                {errors.employer && <span>{errors.employer.message}</span>}
              </p>
            </FormGroup>
            <Flip top opposite collapse when={employedIsOpen}>
              <FormGroup>
                <p>
                  Address <span className="icon-danger">*</span>
                </p>
                <Input
                  name="address"
                  defaultValue={employment ? employment.address : ""}
                  className="border-input"
                  innerRef={register({
                    required: employedIsOpen
                      ? "Please give the Employers Address"
                      : null,
                  })}
                  placeholder="Address"
                  type="text"
                />
                <p className="text-danger small">
                  {errors.address && <span>{errors.address.message}</span>}
                </p>
              </FormGroup>

              <FormGroup>
                <p>
                  Email Id <span className="icon-danger">*</span>
                </p>
                <Input
                  className="border-input"
                  name="employer_emailid"
                  defaultValue={employment ? employment.employer_emailid : ""}
                  placeholder="Employers Email Id"
                  type="email"
                  innerRef={register({
                    required: employedIsOpen
                      ? "Please give the Employers Email"
                      : null,
                  })}
                  // }}
                />
                <p className="text-danger small">
                  {errors.employer_emailid && (
                    <span>{errors.employer_emailid.message}</span>
                  )}
                </p>
              </FormGroup>

              <FormGroup>
                <p>
                  Phone <span className="icon-danger">*</span>
                </p>
                <Input
                  className="border-input"
                  name="employer_phone"
                  defaultValue={employment ? employment.employer_phone : ""}
                  placeholder="Employers Phone Number"
                  type="text"
                  innerRef={register({
                    required: employedIsOpen
                      ? "Please give the Employers Phone Number"
                      : null,
                  })}
                />
                <p className="text-danger small">
                  {errors.employer_phone && (
                    <span>{errors.employer_phone.message}</span>
                  )}
                </p>
              </FormGroup>
            </Flip>
            <FormGroup>
              <p>
                Annual Income <span className="icon-danger">*</span>
              </p>
              <Input
                name="annual_income"
                defaultValue={employment ? employment.annual_income : ""}
                className="border-input"
                placeholder="Annual Income"
                innerRef={register({
                  required: "Please give the annual income",
                })}
                type="text"
              />
              <p className="text-danger small">
                {errors.annual_income && (
                  <span>{errors.annual_income.message}</span>
                )}
              </p>
            </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <p>
                    Years {employedIsOpen ? "in Job" : "receiving"}{" "}
                    <span className="icon-danger">*</span>
                  </p>
                  <Controller
                    as={Select}
                    options={years}
                    name="years_in_job"
                    className="text-dark"
                    control={control}
                    onChange={onYearsChange}
                    //
                  />
                  <p className="text-danger small">
                    {yearType
                      ? ""
                      : errors.years_in_job && (
                          <span>{errors.years_in_job.message}</span>
                        )}
                  </p>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <p>
                    Months {employedIsOpen ? "in Job" : "receiving"}{" "}
                    <span className="icon-danger">*</span>
                  </p>

                  <Controller
                    as={Select}
                    options={months}
                    name="months_in_job"
                    className="text-dark"
                    control={control}
                    onChange={onMonthsChange}
                    //
                  />
                  <p className="text-danger small">
                    {monthType
                      ? ""
                      : errors.months_in_job && (
                          <span>{errors.months_in_job.message}</span>
                        )}
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <>
              {/* Previous Employment */}
              <Flip top opposite collapse cascade when={prevEmployment}>
                <h6>Previous Employment</h6>
                <small>{`Since you have been ${employmentType.label} employment for less than 2 years we need some more information`}</small>
                <hr />

                <FormGroup>
                  <p>
                    Prev Employment Type <span className="icon-danger">*</span>
                  </p>
                  <FormGroup inline>
                    {selectEmploymentOptions.map((val, i) => {
                      return (
                        <Input
                          key={i}
                          type="radio"
                          id={i}
                          name="prev_employment_type"
                          label={`${val.label}`}
                          checked={val.value === prevEmploymentType}
                          onChange={() => onPrevEmploymentChange(val.value)}
                          onClick={() => onPrevEmploymentChange(val.value)}
                        />
                      );
                    })}
                  </FormGroup>
                  {/* <Controller
                      as={<Select classNamePrefix={"my-custom-react-select"} />}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => `${option.value}`}
                      options={selectEmploymentOptions}
                      value={prevEmploymentType ? prevEmploymentType : null}
                      name="prev_employment_type"
                      className="text-dark"
                      control={control}
                      onChange={onPrevEmploymentChange}
                    /> */}
                  <p className="text-danger small">
                    {prevEmploymentType
                      ? ""
                      : errors.prev_employment_type && (
                          <span>{errors.prev_employment_type.message}</span>
                        )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <p>
                    {prevEmployerName} <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="prev_employer"
                    defaultValue={employment ? employment.prev_employer : ""}
                    placeholder={`${prevEmployerName}`}
                    innerRef={register({
                      required: prevEmployment
                        ? `Please give the ${prevEmployerName} Name`
                        : false,
                    })}
                    type="text"
                  />
                  <p className="text-danger small">
                    {errors.prev_employer && (
                      <span>{errors.prev_employer.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Flip>
              {/* Employed vs Other Benefits */}
              <Collapse isOpen={prevEmployment && prevEmployedIsOpen}>
                <FormGroup>
                  <p>
                    Prev Employer Address <span className="icon-danger">*</span>
                  </p>
                  <Input
                    name="prev_address"
                    defaultValue={employment ? employment.address : ""}
                    className="border-input"
                    innerRef={register({
                      required:
                        prevEmployedIsOpen && prevEmployment
                          ? "Please give the Prev Employers Address"
                          : false,
                    })}
                    placeholder="Prev Address"
                    type="text"
                  />
                  <p className="text-danger small">
                    {errors.prev_address && (
                      <span>{errors.prev_address.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <p>
                    Email Id <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="prev_employer_emailid"
                    defaultValue={
                      employment ? employment.prev_employer_emailid : ""
                    }
                    placeholder="Employers Email Id"
                    type="email"
                    innerRef={register({
                      required:
                        prevEmployedIsOpen && prevEmployment
                          ? "Please give the Employers Email"
                          : false,
                    })}
                  />
                  <p className="text-danger small">
                    {errors.prev_employer_emailid && (
                      <span>{errors.prev_employer_emailid.message}</span>
                    )}
                  </p>
                </FormGroup>

                <FormGroup>
                  <p>
                    Phone <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="prev_employer_phone"
                    defaultValue={
                      employment ? employment.prev_employer_phone : ""
                    }
                    placeholder="Employers Phone Number"
                    type="text"
                    innerRef={register({
                      required:
                        prevEmployedIsOpen && prevEmployment
                          ? "Please give the Prev Employers Phone Number"
                          : false,
                    })}
                  />
                  <p className="text-danger small">
                    {errors.prev_employer_phone && (
                      <span>{errors.prev_employer_phone.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Collapse>
              {/* Previous Is Employed */}
              <Flip top opposite collapse when={prevEmployment}>
                <FormGroup>
                  <p>
                    Previous Annual Income{" "}
                    <span className="icon-danger">*</span>
                  </p>
                  <Input
                    name="prev_annual_income"
                    defaultValue={
                      employment ? employment.prev_annual_income : ""
                    }
                    className="border-input"
                    placeholder="Previous Annual Income"
                    innerRef={register({
                      required: prevEmployment
                        ? "Please give the previous annual income"
                        : false,
                    })}
                    type="text"
                  />
                  <p className="text-danger small">
                    {errors.prev_annual_income && (
                      <span>{errors.prev_annual_income.message}</span>
                    )}
                  </p>
                </FormGroup>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <p>
                        Years {prevEmployedIsOpen ? "in Job" : "receiving"}
                        <span className="icon-danger">*</span>
                      </p>
                      <Controller
                        as={Select}
                        options={years}
                        name="prev_years_in_job"
                        className="text-dark"
                        control={control}
                        onChange={onPrevYearsChange}
                      />
                      <p className="text-danger small">
                        {prevYearType
                          ? errors.prev_years_in_job && (
                              <span>{errors.prev_years_in_job.message}</span>
                            )
                          : null}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <p>
                        Months {prevEmployedIsOpen ? "in Job" : "receiving"}
                        <span className="icon-danger">*</span>
                      </p>

                      <Controller
                        as={Select}
                        options={months}
                        name="prev_months_in_job"
                        className="text-dark"
                        control={control}
                        onChange={onPrevMonthsChange}
                      />
                      <p className="text-danger small">
                        {prevMonthType
                          ? errors.prev_months_in_job && (
                              <span>{errors.prev_months_in_job.message}</span>
                            )
                          : null}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Flip>
            </>

            <div className="d-flex justify-content-between">
              <div className="p-2">
                <Button
                  className="btn"
                  color="secondary"
                  onClick={() => GoBack()}
                >
                  Prev
                </Button>
              </div>
              <div className="p-2">
                <Button className="btn" color="danger" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </LargeContainer>
      </Container>
    </>
  );
};

export default Employment;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  height: 1500px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

// const BouncyDiv = styled.div`
//   padding: 5px;
//   margin: 5px;
//   border-radius: 5px;
//   // cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   align-items: space-between;
//   justify-content: center; // left to right
//   // border-color: #000000;
//   // border-style: solid;
//   // border-width: 1px;
//   // &:hover {
//   //   border-color: #ff0000;
//   //   border-style: solid;
//   //   border-width: 1px;
//   // }
// `;

// const HeaderRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: space-between;
//   justify-content: space-between; // left to right
// `;
// const MainContent = styled.div`
//   padding: 5px;
//   // min-height: 60px;
//   background-color: #ffffff;
//   margin: 5px;
//   font-size: large;
// `;
