import React from "react";
import styled, { keyframes } from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-date-picker";

// reactstrap components
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Container,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
} from "reactstrap";

import { LeftArrow } from "utilities/SocialIcons";

import config from "_config";

import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

import LeadSource from "utilities/LeadSource";
const email_validation =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const Start = ({ setDirection, setInSection, closeApplication }) => {
  const dispatch = useDispatch();
  const isMountedRef = React.useRef(null);
  const { register, handleSubmit, watch, errors, setValue, control } =
    useForm();
  const [leadSource, SetLeadSource] = React.useState(LeadSource());
  const [contactInfo, setContactInfo] = React.useState([]);
  const [agreed, setAgreed] = React.useState(false);

  // React.useEffect(() => {
  //   register({ name: "year" }, { required: "Please select a Year" });
  // }, [register]);
  const { get_application } = useSelector(
    (state) => ({
      get_application: state.application.application,
    }),
    shallowEqual
  );

  function GoBack() {
    setDirection(false);
    setInSection(2);
  }
  // user ip
  const [userIp, setUserIp] = React.useState(get_application.userIp);

  React.useEffect(() => {
    isMountedRef.current = true;

    if (!userIp) {
      if (isMountedRef.current) {
        async function fetchData() {
          const res = await fetch("https://api.ipify.org?format=json");
          res
            .json()
            .then((res) => {
              setUserIp(res.ip);
            })
            .catch((err) => console.log(err));
        }

        fetchData();
      }
    }
    return () => (isMountedRef.current = false);
  });
  // user ip
  React.useEffect(() => {
    if (get_application.contact_info) {
      setContactInfo(get_application.contact_info);
    }
  }, [get_application]);

  React.useEffect(() => {
    return () => {
      // console.log("cleaned up");
      isMountedRef.current = false;
    };
  }, []);

  // console.log(get_application)
  // console.log(get_application.contact_info)

  React.useEffect(() => {
    setAgreed(get_application.agree);
  }, [get_application]);

  React.useEffect(() => {
    setValue("agree", contactInfo.agree);
  });

  const onSubmit = (data) => {
     console.log("Contact Info Data :------>", data);

    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        contact_info: data,
        lead_source: {
          web_lead_stage: config.web_lead_stage,
          web_lead_type: config.web_lead_type_credit_app,
          web_lead_source: config.web_lead_source,
          campaign_code: leadSource.campaign_code,
          // deviceDetected
          // browser: leadSource.deviceDetected.browser,
          deviceType: leadSource.deviceType,
          gclid: leadSource.gclid,
          isBrowser: leadSource.isBrowser,
          isMobile: leadSource.isMobile,
          browser: leadSource.browser,
          lead_source: leadSource.lead_source,
          mssclid: leadSource.mssclid,
          osName: leadSource.osName,
          osVersion: leadSource.osVersion,
          pixelid: leadSource.pixelid,
          userIp: userIp ? userIp : "0.0.0.0",
          utm_campaign: leadSource.utm_campaign,
          utm_medium: leadSource.utm_medium,
          utm_source: leadSource.utm_source,
        },
      },
    });
    // MakeContactVisible(2)
    setDirection(true);
    setInSection(4);
  };
  //
  // console.log("lead source", contactInfo);
  return (
    <>
      <Container fluid>
        <LargeContainer>
          <Button close onClick={closeApplication} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                  <LeftArrow onClick={GoBack} />{" "}
                </span>
                Personal Info
              </h2>

              <p>Some information to help us complete the form.</p>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg="12">
              <BouncyDiv>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <p>
                          First Name <span className="icon-danger">*</span>
                        </p>
                        <Input
                          className="border-input"
                          name="first_name"
                          defaultValue={
                            contactInfo ? contactInfo.first_name : ""
                          }
                          placeholder="First name"
                          type="text"
                          innerRef={register({
                            required: "Please enter First Name",
                          })}
                        />
                        <p className="text-danger small">
                          {errors.first_name && (
                            <span>{errors.first_name.message}</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <p>Middle Name</p>
                        <Input
                          className="border-input"
                          name="middle_name"
                          defaultValue={
                            contactInfo ? contactInfo.middle_name : ""
                          }
                          placeholder="Middle name"
                          type="text"
                          innerRef={register()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <p>
                          Last Name <span className="icon-danger">*</span>
                        </p>
                        <Input
                          className="border-input"
                          placeholder="Last name"
                          defaultValue={
                            contactInfo ? contactInfo.last_name : ""
                          }
                          type="text"
                          name="last_name"
                          innerRef={register({
                            required: "Please enter Last Name",
                          })}
                        />
                        <p className="text-danger small">
                          {errors.last_name && (
                            <span>{errors.last_name.message}</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <p>
                          Email Id <span className="icon-danger">*</span>
                        </p>
                        <Input
                          className="border-input"
                          placeholder="Email Id"
                          name="email_id"
                          defaultValue={contactInfo ? contactInfo.email_id : ""}
                          type="email"
                          innerRef={register({
                            required: "Please Enter Email",
                            pattern: {
                              value: email_validation,
                              message: "invalid email address",
                            },
                            maxlength: 20,
                          })}
                        />
                        <p className="text-danger small">
                          {errors.email_id && (
                            <span>{errors.email_id.message}</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <p>
                          Phone <span className="icon-danger">*</span>
                        </p>
                        <Input
                          className="border-input"
                          name="phone"
                          defaultValue={contactInfo ? contactInfo.phone : ""}
                          placeholder="Enter the Phone Number"
                          type="text"
                          innerRef={register({
                            required: "Please enter phone number",
                            minlength: 10,
                          })}
                        />
                        <p className="text-danger small">
                          {errors.phone && <span>{errors.phone.message}</span>}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <p>SIN</p>
                        <Input
                          className="border-input"
                          name="sin"
                          defaultValue={contactInfo ? contactInfo.sin : ""}
                          placeholder="Enter the SIN"
                          type="text"
                          innerRef={register()}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                   
                    
                  </Row>{" "}
                  <FormGroup>
                    <p>
                      Date of Birth <span className="icon-danger">*</span>
                    </p>
                    <Controller
                      as={DatePicker}
                      control={control}
                      name="date_of_birth"
                      // required
                      innerRef={register({
                        required: "DOB is required",
                      })}
                    />

                      <p className="text-danger small">
                          {errors.date_of_birth && (
                            <span>{errors.date_of_birth.message}</span>
                          )}
                        </p>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Controller
                        as={<Input type="checkbox" />}
                        name="agreed_to_terms"
                        defaultValue={false}
                        value={true}
                        control={control}
                        innerRef={register({
                          required: "Please Check this box",
                        })}
                      />
                      I agree to terms and conditions{" "}
                      <span className="form-check-sign" />
                    </Label>
                    <p className="text-danger small">
                      {errors.agreed_to_terms && (
                        <span>{errors.agreed_to_terms.message}</span>
                      )}
                    </p>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <div className="p-2"></div>
                    <div className="p-2">
                      <Button className="btn" color="danger" type="submit">
                        Start
                      </Button>
                    </div>
                  </div>
                </Form>
              </BouncyDiv>
            </Col>
          </Row>
        </LargeContainer>
      </Container>
    </>
  );
};

export default Start;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;

const BouncyDiv = styled.div`
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center; // left to right
  // border-color: #000000;
  // border-style: solid;
  // border-width: 1px;
  // &:hover {
  //   border-color: #ff0000;
  //   border-style: solid;
  //   border-width: 1px;
  // }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between; // left to right
`;
const MainContent = styled.div`
  padding: 5px;
  // min-height: 60px;
  background-color: #ffffff;
  margin: 5px;
  font-size: large;
`;
