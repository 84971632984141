import React from "react";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// reactstrap components
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Collapse,
  // Label,
  FormGroup,
  Container,
} from "reactstrap";

import { LeftArrow } from "utilities/SocialIcons";

import Select from "react-select";
import { SG_UPDATE_FINANCE_APPLICATION } from "constants/actions";

var SelectResidenceList = [
  { value: 1, label: "Rented" },
  { value: 2, label: "Owned" },
];

let years = [];
_.times(20, (i) => {
  years.push({ value: i, label: `${i}` });
});

let months = [];
_.times(11, (i) => {
  months.push({ value: i, label: `${i}` });
});

var postal_code_validator = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const OwnHouse = ({ setDirection, setInSection, closeApplication }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, errors, control } = useForm();

  const [residence, setResidence] = React.useState([]);
  const [residenceType, setResidenceType] = React.useState(false);

  const { get_residence } = useSelector(
    (state) => ({
      get_residence: state.application.application,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (_.has(get_residence, "residence")) {
      setResidence(get_residence.residence);
      setResidenceType(get_residence.residence.residence_type);
    }
  }, [get_residence]);

  const onResidenceChange = (selectedOption) => {
    setResidenceType(selectedOption[0]);
  };

  // const [showhide, setShowhide] = React.useState();
  // const handleshowhide=(event)=>{
  //   console.log("Callll")
  //   const getuser = event.target.value;    
  //   setShowhide(getuser);
  // }

  React.useEffect(() => {
    console.log("call");
    setValue("residence_type", residenceType);
  }, [setValue, residenceType]);

  React.useEffect(() => {
    register(
      { name: "residence_type" },
      { required: "Please select a Source of Income" }
    );
  });

 

  const onSubmit = (data) => {
    console.log(data);

    dispatch({
      type: SG_UPDATE_FINANCE_APPLICATION,
      payload: {
        residence: {
          ...data,
        },
      },
    });
    setDirection(true);
    setInSection(6);
  };
  //
  function GoBack() {
    setDirection(false);
    setInSection(4);
  }

  //
  return (
    <>
      <Container>
        <LargeContainer>
          <Button close onClick={closeApplication} />
          <Row className="justify-content-center text-center">
            <Col md="8">
              <h2 className="display-4">
                <span style={{ cursor: "pointer" }}>
                  <LeftArrow onClick={GoBack} />{" "}
                </span>
                Home
              </h2>
            </Col>
          </Row>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <p>
                  Address <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    name="address"
                    placeholder="Address"
                    type="text"
                    innerRef={register({
                      required: "Please enter address",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.address && (
                      <span>{errors.address.message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
              
              <Col md="6">
                <FormGroup>
                  <p>
                  How Long At Address <span className="icon-danger">*</span>
                  </p>
                  <Input
                    className="border-input"
                    placeholder="Number of years"
                    type="text"
                    name="address_years"
                    innerRef={register({
                      required: "Please Enter Number of Years",
                    })}
                  />
                  <p className="text-danger small">
                    {errors.address_years && (
                      <span>{errors.address_years .message}</span>
                    )}
                  </p>
                </FormGroup>
              </Col>
              <Col md="6">
              <FormGroup>
              <p>
                Rent/Own
                <span className="icon-danger">*</span>
              </p>

              <Controller
                as={Select}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                options={SelectResidenceList}
                value={residenceType ? residenceType : null}
                name="residence_type"
                className="text-dark"
                control={control}
                onChange={onResidenceChange}
                // onSelect={handleshowhide}
              />
              <p className="text-danger small">
                {residenceType
                  ? ""
                  : errors.residence_type && (
                      <span>{errors.residence_type.message}</span>
                    )}
              </p>
            </FormGroup>
              </Col>
              
               
              <Col md="6">
                <FormGroup>
                  <p>
                  If rent, how much monthly 
                  {/* <span className="icon-danger">*</span> */}
                  </p>
                  <Input
                    className="border-input"
                    name="rent_in_month"
                    placeholder="Monthly Rent"
                    type="text"
                    innerRef={register()}
                  />
                  <p className="text-danger small">
                    {errors.phone && <span>{errors.phone.message}</span>}
                  </p>
                </FormGroup>
              </Col>
              
            </Row>
            
            <div className="d-flex justify-content-between">
              <div className="p-2">
                <Button
                  className="btn"
                  color="secondary"
                  onClick={() => GoBack()}
                >
                  Prev
                </Button>
              </div>
              <div className="p-2">
                <Button className="btn" color="danger" type="submit">
                  Next
                </Button>
              </div>
            </div>
          </Form>
        </LargeContainer>
      </Container>
    </>
  );
};

export default OwnHouse;

const LargeContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  height: 100%;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  color: #54595f;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  margin-top: 20px;
  margin-bottom: 100px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
`;
